import { Image, Link, RawHtml, Text, Video } from '~/shared/components';
import React, { useRef } from 'react';
import { M75MediaAndTextModule } from '~/lib/data-contract';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { AlignContent } from '../AlignContent';
import { ModuleContainer } from '../ModuleContainer';
import {
    StyledActions,
    StyledGridArea,
    StyledM75,
    StyledM75Container,
    StyledM75Content,
    StyledMedia,
} from './styled';
import { queries } from '~/theme/breakpoints';
import { Button } from '~/shared/components/Button/Button';
import { useIntersection } from 'react-use';

export type M75MediaTextProps = M75MediaAndTextModule;

export const M75MediaText = ({
    image,
    imageAltText,
    video,
    headline,
    subHeadline,
    subSubHeadline,
    text,
    callToAction,
    spacingBottom,
    spacingTop,
    priority,
    color,
    mediaSize = 'default',
    mediaHorizontalAlignment = 'left',
    horizontalAlignment = 'left',
    verticalAlignment = 'center',
    alternativeBackgroundColor,
    alternativeTextColor,
    ...rest
}: M75MediaTextProps) => {
    const { backgroundColor, textColor } = useModuleTheme(rest);
    const ref = useRef<HTMLDivElement>(null);
    const shouldLoad = useIntersection(ref, { root: null, rootMargin: '0px' });
    const mediaType = video ? 'video' : 'image';

    return (
        <StyledM75Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
            <ModuleContainer spacingBottom={'none'} spacingTop={'none'} {...rest}>
                <StyledM75>
                    <StyledM75Content
                        backgroundColor={backgroundColor}
                        mediaSize={mediaSize}
                        mediaAlignment={mediaHorizontalAlignment}
                    >
                        <AlignContent alignment={horizontalAlignment} position={verticalAlignment}>
                            {headline && (
                                <Text
                                    children={headline.text}
                                    as={headline.tag}
                                    variant={headline.style}
                                    color={headline?.textColor || textColor}
                                />
                            )}
                            {subHeadline && (
                                <Text
                                    children={subHeadline.text}
                                    as={subHeadline.tag}
                                    variant={subHeadline.style}
                                    color={subHeadline?.textColor || textColor}
                                />
                            )}
                            {subSubHeadline && (
                                <Text
                                    children={subSubHeadline.text}
                                    as={subSubHeadline.tag}
                                    variant={subSubHeadline.style}
                                    color={subSubHeadline?.textColor || textColor}
                                />
                            )}
                            {text ? <RawHtml html={text} /> : null}
                            <StyledActions>
                                {callToAction && (
                                    <Button
                                        variant={color}
                                        title={callToAction.title}
                                        textColor={textColor}
                                        backgroundColor={backgroundColor}
                                        alternativeBackgroundColor={alternativeBackgroundColor}
                                        alternativeTextColor={alternativeTextColor}
                                        as={Link}
                                        href={callToAction.url}
                                        children={callToAction.text}
                                        target={callToAction.target}
                                    />
                                )}
                            </StyledActions>
                        </AlignContent>
                        <StyledGridArea area="media">
                            <StyledMedia ref={ref} alignment={mediaHorizontalAlignment}>
                                {mediaType === 'image' && image ? (
                                    callToAction ? (
                                        <Link
                                            href={callToAction?.url}
                                            title={callToAction?.title}
                                            target={callToAction?.target}
                                        >
                                            <Image
                                                {...image}
                                                priority={priority}
                                                layout="responsive"
                                                alt={image.alt || imageAltText}
                                                sizes={`${queries.sm} 50vw, ${queries.contentMax} 50vw, 720px`}
                                            />
                                        </Link>
                                    ) : (
                                        <Image
                                            {...image}
                                            alt={image.alt || imageAltText}
                                            layout="responsive"
                                            sizes={`${queries.sm} 50vw, ${queries.contentMax} 50vw, 720px`}
                                        />
                                    )
                                ) : null}
                                {mediaType === 'video' &&
                                video &&
                                (!!shouldLoad?.isIntersecting ?? priority) ? (
                                    <Video
                                        controls={false}
                                        src={video.src}
                                        loop={video.loop}
                                        playing={video.autoPlay}
                                        posterSrc={image?.src}
                                        muted={video.mute}
                                    />
                                ) : null}
                            </StyledMedia>
                        </StyledGridArea>
                    </StyledM75Content>
                </StyledM75>
            </ModuleContainer>
        </StyledM75Container>
    );
};
